import React from "react"
import { injectIntl } from "gatsby-plugin-intl"
import {graphql} from 'gatsby';

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Banner from "../components/banner";
import Section from "../components/section";
import ImagesWithListContainer from '../components/images-with-list-container';
import AnyQuestions from "../components/any-questions";

// Fetches src/data/services.json
export const services = graphql`
    query ServicesQuery
    {
        allServicesJson {
            edges {
                node {
                    colSize
                    className
                    imageName
                    imagePath
                    imageAlt
                    title
                    list { text }
                }
            }
        }
    }
`;

const Services = (props) => {
    return <Layout>
        <SEO 
            title={props.intl.formatMessage({id: "Services - Adventurees Alliance"})} 
            description={props.intl.formatMessage({id: "Consulting, mentoring and specific training for entrepreneurs and investors"})}
            location={props.location}
            image="/images/sharing/home-services.jpg"
        />

        <Banner 
            className="banner services" 
            filename="services-banner.jpg" 
            title={props.intl.formatMessage({id: "Build real business with us"})}
            subtitle={props.intl.formatMessage({id: "We contribute our experience and validated methodologies with more than 1000 businesses"})}
        />
        <Section>
            <div className="row align-items-center page-introduction">
                <div className="col-md-6 introduction-left clients-left">
                    <p>{props.intl.formatMessage({id: "Count on us to achieve your goals"})}</p>
                    <h3>{props.intl.formatMessage({id: "Develop your entrepreneurial and investment environment"})}</h3>
                </div>
                <div className="col-md-6 introduction-right clients-right">
                    <p>{props.intl.formatMessage({id: "We work with all types of entities that develop entrepreneurship and corporate venture environments. Years of experience in the world of business growth and investment allow us to help organizations connect technology, business and capital. Contact us to schedule a meeting."})}</p>
                    <p>{props.intl.formatMessage({id: "Our strength lies in"})}:</p>
                    <ul>
                        <li>{props.intl.formatMessage({id: "Extensive know-how in business and investment, with a business development model recognized as Best Practice by the International Business Incubator Association"})}</li>
                        <li>{props.intl.formatMessage({id: "A portfolio of multinational investors and a financing platform authorised by the National Securities Market Commission"})}</li>
                        <li>{props.intl.formatMessage({id: "A network of analysts, mentors and advisors specialized in a diversity of sectors and technologies"})}</li>
                    </ul>
                </div>
            </div>
        </Section>
        <Section title={props.intl.formatMessage({id: "Get to know our services"})}>
            <ImagesWithListContainer elements={props.data.allServicesJson.edges} className="services" />
        </Section>

        <AnyQuestions />
    </Layout>
}

export default injectIntl(Services);
