import React from "react";
import { injectIntl } from "gatsby-plugin-intl";

import Image from "./image";
import Section from "./section";
import params from "../data/params";

const AnyQuestions = (props) =>
{
    return <Section title={props.intl.formatMessage({id: "Any questions?"})}>
        <div className="container contact-us-footer">
            <div className="contact-image">
                <Image filename={"call_icon.png"} alt="Call us" />
            </div>
            <p>{props.intl.formatMessage({id: "Contact us at"})} <a href="tel:+34 91 193 00 61">+34 91 193 00 61</a></p>
            <p>{props.intl.formatMessage({id: "Or you can send us an email to"})} <a href={"mailto://"+params.CONTACT_EMAIL}>{params.CONTACT_EMAIL}</a></p>
        </div>
    </Section>
}

export default injectIntl(AnyQuestions);