import React from "react";
import PropTypes from 'prop-types';

import { injectIntl } from "gatsby-plugin-intl";

const ImageWithList = (props) => {
    let imageColSize = 3;
    let listColSize = 9;
    if (props.element.wideTitle)
    {
        imageColSize = 4;
        listColSize = 8;
    }
    return <div className={"image-with-list-element col-md-"+props.element.colSize + " " + props.element.className}>
        <div className="row justify-content-center">
            <div className={"image-container col-md-"+imageColSize}>
                <img src={props.element.imagePath} alt={props.intl.formatMessage({id: props.element.imageAlt})} />
                <h3>{props.intl.formatMessage({id: props.element.title})}</h3>
            </div>
            <div className={"col-md-"+listColSize+" col-sm-"+listColSize+" list-container align-self-center"}>
                <ul>
                    {props.element.list.map((li, index) => {
                        return <li key={index}>{props.intl.formatMessage({id: li.text})}</li>
                    })}
                </ul>
            </div>
        </div>
    </div>
}

ImageWithList.propTypes = {
    element: PropTypes.shape({
        wideTitle: PropTypes.string,
        colSize: PropTypes.number.isRequired,
        className: PropTypes.string,
        imagePath: PropTypes.string.isRequired,
        imagealt: PropTypes.string.isRequired,
        listColSize: PropTypes.string.isRequired,
        list: PropTypes.array.isRequired
    }).isRequired,
}

export default injectIntl(ImageWithList);
